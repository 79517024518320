/* burger icon */
.burger-menu {
  position:relative;
  float:right;
  margin-top:18px;
  height:32px;
  width:30px;
  background:transparent;
  cursor:pointer;
  outline:none;
  -webkit-tap-highlight-color: transparent;
}
.burger-menu div {
  position:absolute;
  width:28px;
  height:2px;
  background-color:#555;
  border-radius:2px;
  border:1px solid #cecece;
}
.burger-menu div:first-child {
  opacity:1;
  top:0;
  transition:.1s opacity ease, .3s top ease;
}
.burger-menu.open div:first-child {
  opacity:0;
  top:7px;
}
.burger-menu div:nth-child(2), .burger-menu div:nth-child(3) {
  top:7px;
  transition:.4s transform ease;
}
.burger-menu.open div:nth-child(2) {
  transform:rotate(45deg);
}
.burger-menu.open div:nth-child(3) {
  transform:rotate(-45deg);
}
.burger-menu div:nth-child(4) {
  opacity:1;
  top:14px;
  transition:.1s opacity ease, .3s top ease;
}
.burger-menu.open div:nth-child(4) {
  opacity:0;
  top:7px;
}
/* navigation */
nav {
  position:absolute;
  top:50px;
  left:0;
  opacity:0;
  height:0;
  width:100%;
  background:linear-gradient(180deg, #cecece 5%, #fff 95%);
  transition:.2s height ease-in-out, .2s opacity ease-in-out;
  z-index:1000;
  clear:both;
  overflow:hidden;
}
nav.open {
  height:auto;
  opacity:1;
}
nav a, .logoutButton {
  text-align:center;
  cursor:pointer;
  display:inline-block;
  vertical-align:top;
  border-right:none;
  width:100%;
  border-right:none;
  color: #555;
  text-decoration:none;
}
nav a {
  padding:0;
  height:0;
  opacity:0;
  border-bottom: 1px solid #c4c4c4;
  background:transparent;
  background-image: linear-gradient(#cecece,#cecece);
  background-size: 100% 0;
  background-repeat: no-repeat;
  transition: .2s;
}
nav a:first-child {
  border-top: 1px solid #c4c4c4;
}
nav a.open {
  opacity:1;
  padding:15px 0;
  height:15px;
}
.logoutButton {
  float:none;
  padding:0;
  height:45px;
  background:transparent;
  background-image: linear-gradient(#cecece,#cecece);
  background-size: 100% 0;
  background-repeat: no-repeat;
  transition: .2s;
}
.logoutButton img {
  display:block;
  width:18px;
  margin:0 auto;
}
nav a:last-child {
  border-right:none;
}
nav a, nav a:visited, nav a:active {
  outline:none;
  color:#555;
  -webkit-tap-highlight-color: transparent;
}
nav a:hover, .logoutButton:hover {
  background-size:100% 100%;
}
.hide {
  display:none;
}
@media only screen and (min-width:545px){
  nav {
    position:relative;
    top:0;
    left:0;
    opacity:1;
    height:auto;
    display:block;
    background:none;
  }
  nav a, .logoutButton {
    width:auto;
    border-right:1px solid #cecece;
  }
  nav a {
    opacity:1;
    height:35px;
    padding:15px 30px 0 30px;
    border-bottom:none;
  }
  nav a:first-child {
    border-top: none;
  }
  .logoutButton {
    float:right;
    width:auto;
    padding:0 15px;
    height:50px;
  }
  .burger-menu {
    display:none;
  }
}