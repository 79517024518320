* {
  padding:0;
  margin:0;
  border:none;
}
body {
  margin: 0;
  font-size:14px;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  background-color:#1c1c1c;
}
h1 {
  font-size:20px;
  text-transform:uppercase;
  font-weight:100;
}
h2 {
  font-size:16px;
  text-transform:uppercase;
  font-weight:100;
}
h1.mainTitle, h2.mainTitle {
  margin:40px 25px 70px 25px;
  text-shadow: 2px 2px 1px #555, -2px -2px 1px #000;
}
.logo {
  display:block;
  width:100%;
  max-width:100px;
  height:auto;
  position:relative;
  z-index:99999;
}
.textCenter {
  text-align:center;
}
@media only screen and (min-width:480px){
  h1 {
    font-size:24px;
    text-transform:uppercase;
    font-weight:100;
  }
  h2 {
    font-size:20px;
    text-transform:uppercase;
    font-weight:100;
  }
}
@media only screen and (min-width:768px){
  h1 {
    font-size:36px;
    text-transform:uppercase;
    font-weight:100;
  }
  h2 {
    font-size:32px;
    text-transform:uppercase;
    font-weight:100;
  }
}

/* mobile first */

/* header */
header {
  background-color:#fff;
  width:100%;
  height:50px;
  position:fixed;
  top:0;
  z-index:10000;
  box-shadow:0 0px 20px #000;
  background:linear-gradient(0deg, #cecece 5%, #fff 95%);
}
header span {
  display:inline-block;
  vertical-align:top;
  height:32px;
}
header span:first-child {
  width:10%;
  height:35px;
  padding:10px 2% 0;
}
header span:last-child {
  width:84%;
  color:#555;
  text-transform:uppercase;
}

/* main */
main {
  overflow:hidden;
  background-color:#1c1c1c;
  background: repeating-linear-gradient(45deg, transparent, transparent 1px, #272727 0, #272727 20px);
}
/* page main */
.mainPages {
  width:90%;
  margin:50px auto 20px;
  padding:5%;
  color:#fff;
}

/* footer */
footer {
  position:fixed;
  z-index:10000;
  bottom:0;
  background-color:#fff;
  width:100%;
  height:15px;
  box-shadow: 0px 0px 5px #000;
  padding:10px 40px;
  font-size:10px;
}

/* gallery / showcase */
.gallery div.galleryEntry {
  display:inline-block;
  margin:0 25px 25px 25px;
  width:auto;
  height:450px;
  border:1px solid #505050;
  background: linear-gradient(45deg, #cecece 95%, #fff 95%,#555 95%, #cecece 100%);
  box-shadow: 1px 1px 0 1px #0077ff, 3px 3px 0 1px #141414, 5px 5px 10px 0px #0e0e0e, 0 50px 70px -85px #0077ff;
  border-radius:5px;
}
.gallery div.galleryEntry .exampleImage {
  border-radius:5px;
  width:100%;
  height:300px;
}
.gallery div.galleryEntry .exampleText {
  border-radius:0 0 5px 5px;
  background-color:#fff;
  width:auto;
  height:120px;
  color:#555;
  padding:10px 20px 20px 20px;
}
.gallery div.galleryEntry .exampleText h3 {
  font-size:24px;
  margin:0 0 10px 0;
}
@media only screen and (min-width:500px){
  .gallery div.galleryEntry {
    width:380px;
  }
}