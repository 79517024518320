.mainLogin {
  height:80vh;
}
.loginArea {
  background-color:#fff;
  width:250px;
  height:auto;
  padding:20px;
  margin:120px auto;
  border-radius:5px;
  box-shadow:1px 1px 0 0 #000;
}
.loginArea div {
  width:150px;
  margin:0 auto 15px;
  font-size:42px;
  text-transform:uppercase;
}
.loginArea div span, .loginArea div img {
  display:inline-block;
  vertical-align:middle;
}
.loginArea div span {
  letter-spacing:2px;
}
.loginArea div img {
  width:32px;
  height:auto;
  margin:0 5px;
}
.loginArea input[type="text"], .loginArea input[type="password"] {
  width:96%;
  height:35px;
  border:1px solid #cecece;
  margin:0 0 10px 0;
  padding:0 2%;
  border-radius:5px;
}
.loginArea button {
  width:100%;
  height:35px;
  cursor:pointer;
  margin:10px 0 0 0;
  border-radius:5px;
  border:1px solid #cecece;
  background-image:linear-gradient(#cecece,#cecece);
  background-size:100% 0;
  background-repeat:no-repeat;
  transition:.2s;
}
.loginArea button img {
  display:inline-block;
  vertical-align: middle;
  width:18px;
}
.loginArea button:hover {
  background-size:100% 100%;
}
