.todolist {
  display:none;
}

/* Play Cards */
.stratCardsMain {
  letter-spacing:0.1em;
}
.stratCardsMain h1 {
  font-size:21px;
  text-align:center;
  color:#fff;
  margin: 40px 0 20px;
  letter-spacing:0.2em;
  text-shadow: 2px 2px 5px #000, -2px -2px 5px #363636;
}
.contentWrapper {
  margin-top:50px;
  color:#fff;
  text-align:center;
}
.contentWrapper h2 {
  font-size:16px;
  letter-spacing:0.2em;
  color: #999;
  margin: 30px 0 15px;
  letter-spacing:0.1em;
  text-shadow: 2px 2px 5px #000, -2px -2px 5px #363636;
}
@media only screen and (min-width:480px){
  .stratCardsMain h1 {
    font-size:36px;
  }
  .contentWrapper h2 {
    font-size:24px;
  }
}
.filterArea {
  display:none; 
  width:98%;
  padding:1%;
  background-color:#555;
  color:#fff;
  border-radius:5px;
  margin:25px 0 60px;
  font-size:16px;
}
.filterArea span, .filterArea button {
   display:inline-block;
}
.filterArea span {
  margin:0 0 12px;
  width:100%;
}
 .filterArea button {
  display:inline-block;
  border:1px solid #555;
  background-color:#cecece;
  color:#555; 
  padding:6px 10px;
  font-size:16px;
  border-radius:5px;
  margin:0 5px 10px;
  cursor:pointer;
}

.card-wrap {
  width:100%;
  height:auto;
  margin:0 auto;
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap:4em;
}
.card-scene {
  display:grid;
  margin:0 auto;
  width:100%;
  height:600px;
  perspective: 600px;
  -webkit-tap-highlight-color: transparent;
}
.card-scene:active, .card-scene:focus {
  outline:none;
}
@media only screen and (min-width:430px){
  .card-wrap {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
  .card-scene {
    width:380px;
  }
}

.card {
  position:relative;
  width:100%;
  height:100%;
  transition:transform 0.5s;
  transform-style:preserve-3d;
  cursor:pointer;
}
.card img {
  display:block;
  width:94%;
  margin:3% 3% 3% 3%;
  border:1px solid #fff;
  border-radius:5px;
}
.card.flipped {
  transform:rotateY(180deg);
}
.card-face {
  position:absolute;
  width:100%;
  height:100%;
  border-radius:5px;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  border:1px solid #505050;
  background: repeating-linear-gradient(45deg,#555,#555 10px,#666 10px,#666 20px);
}
.card-face.front.t {
  box-shadow: 1px 1px 0 1px #6b5200, 3px 3px 0 1px #141414, 5px 5px 10px 0px #0e0e0e, 0 50px 70px -85px #e0ac00;
}
.card-face.back.t {
  transform:rotateY(180deg);
  box-shadow: 1px 1px 0 1px #6b5200, 3px 3px 0 1px #141414, 5px 5px 10px 0px #0e0e0e, 0 50px 70px -85px #e0ac00;
}
.card-face.front.ct {
  box-shadow: 1px 1px 0 1px #00397a, 3px 3px 0 1px #141414, 5px 5px 10px 0px #0e0e0e, 0 50px 70px -85px #0077ff;
}
.card-face.back.ct {
  transform:rotateY(180deg);
  box-shadow: 1px 1px 0 1px #00397a, 3px 3px 0 1px #141414, 5px 5px 10px 0px #0e0e0e, 0 50px 70px -85px #0077ff;
}

/* side marking */
.side-mark {
  width: 8%;
  height: 100%;
  display: inline-block;
  vertical-align:top;
  background-color: #ffb100;
}
.front .side-mark {
  border-radius: 5px 0 0 5px;
}
.back .side-mark {
  border-radius: 0 5px 5px 0;
}
.side-mark.t {
  background: linear-gradient(45deg, #ffb100 10%, #ffe3a5 35%,#ffb100 50%, #fff8d7 80%, #ffb100 100%);
}
.side-mark.ct {
  background: linear-gradient(45deg, #0077ff 10%, #a0caf9  35%,#0077ff 50%, #b8d9ff 80%, #0077ff 100%);
}
.side-mark span {
  display: inline-block;
  position: relative;
  top: 600px;
  left: 6px;
  color: #555;
  letter-spacing: 1em;
  width: 600px;
  height: auto;
  font-size: 16px;
  text-transform: uppercase;
  transform-origin: 0 0;
  transform: rotate(-90deg);
}

/* content */
.content {
  display:inline-block;
  vertical-align:top;
  width:92%;
  height:100%;
  background: repeating-linear-gradient(45deg,#555,#555 10px,#666 10px,#666 20px);
}
.content.back {
  background: none;
  height:auto;
}

@media only screen and (min-width:480px){
  .side-mark {
    width:30px;
  }
  .content {
    width:350px;
  }
}

/* card title */
.card .title {
  width:100%;
  background-color:#fff;
  padding:20px 0;
  color:#555;
}
.card .front .title {
  border-radius:0 5px 0 0;
}
.card .back .title {
  border-radius:5px 0 0 0;
}
.card .title h3 {
  font-size:36px;
  margin-bottom:0;
  text-transform:uppercase;
  color:#696969;
  text-shadow:1px 1px #000;
  letter-spacing:.02em;
}
.card .title p:first-of-type {
  font-size:18px;
  margin-bottom:10px;
  color:#b1b1b1;
  text-transform:uppercase;
}
.card .title p:nth-of-type(2) {
  font-size:14px;
  font-weight:bold;
  color:#242424;
}

/* card content */
.card .content ul {
  list-style:none;
  padding:0 2% 2% 2%;
}
/* card categorie titles */
.card .content div h4 {
  font-size:16px;
  margin-bottom:10px;
  color:#555;
  text-transform:uppercase;
  padding:3%;
  background-color:#fff;
  border-radius:5px 5px 0 0;
  text-shadow:1px 1px #cecece;
}
.card .content div.map-overview h4 {
  border-radius:5px 0 0 0;
}
/* categories main */
.card .content .utility,
.card .content .plan,
.card .content .details {
  border-radius:5px;
  margin:3%;
  text-align:center;
  border:1px dashed #404040;
}
.card .content .map-overview {
  border-radius:5px 0 0 0;
  border-bottom:1px dashed #404040;
  background-color:#999;
}
.card .content .utility {
  background-color:#ddd;
}
.card .content .plan,
.card .content .details {
  background-color:#999;
}
.card .content .utility .icons,
.card .content .utility .icons span {
  display:inline-block;
  vertical-align:middle;
}
.card .content .utility .util-contents {
  padding:0 3% 3% 3%;
}
.card .content .utility .icons {
  margin:0 20px 0 0;
}
.card .content .utility .icons .smoke {
  padding: 0;
  background-repeat: no-repeat;
  background-image:url(../images/smoke-icon.svg);
}
.card .content .utility .icons .flash {
  padding: 0;
  background-repeat: no-repeat;
  background-image:url(../images/flash-icon.svg);
}
.card .content .utility .icons .molly {
  padding: 0;
  background-repeat: no-repeat;
  background-image:url(../images/molly-icon.svg);
}
.card .content .utility .icons:last-child {
  margin:0;
}
.card .content .utility .icons span:first-child {
  color:#555;
  padding-right:10px;
  font-size:16px;
  font-weight:bold;
  text-shadow:1px 1px #999;
}
.card .content .utility .icons span:nth-child(2) {
  width:45px;
  height:40px;
  text-shadow:1px 0 #000;
  font-size:16px;
}
/* category lists */
.card .content div ul li {
  color:#555;
  background-color:#ddd;
  padding:3% 4%;
  text-align:left;
  box-shadow:1px 1px 1px 0 #555;
}
.card .content div ul li:first-child {
  border-radius:3px 3px 0 0;
}
.card .content div ul li:last-child {
  border-radius:0 0 3px 3px;
}
.card .content .utility ul li {
  width:auto;
  margin:0 10px 0 0;
}
.card .content .plan ul li, 
.card .content .details ul li {
  width:92%;
  margin:0 0 2px 0;
  font-size:12px;
  vertical-align:middle;
}
.card .content .plan ul li:before, 
.card .content .details ul li:before {
  content:'';
  display:inline-block;
  vertical-align:top;
  background-size:contain;
  background-repeat:no-repeat;
}
.card .content div ul li.standardText:before {
  height:14px;
  width:18px;
  background-image:url(../images/list-icon.svg);
}
.card .content div ul li.callText:before {
  height:16px;
  width:21px;
  background-image:url(../images/call-icon.svg);
}
.card .content div ul li.optionText:before {
  height:16px;
  width:21px;
  background-image:url(../images/cogwheel-icon.svg);
}
.card div ul:first-child li:last-child, 
.card div ul:nth-child(2) li:last-child, 
.card div ul:nth-child(3) li:last-child {
  margin:0;
}
.card .content div ul li {
  display:inline-block;
}